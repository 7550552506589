import { Component, Input } from '@angular/core';
import { RenderableCheckbox } from '../Models/renderable-checkbox';
import { BaseFormControlComponent } from '../Components/base-form-control.component';
import { ModelPropertyService } from '../Services/modelproperty.service';

@Component({
  selector: 'app-ws-checkbox',
  templateUrl: '../Templates/checkbox.component.html',
  styleUrls: ['../Styles/checkbox.component.scss'],
})
export class CheckboxComponent extends BaseFormControlComponent {
  protected override _renderable: RenderableCheckbox;
  @Input()
  public override get renderable(): RenderableCheckbox {
    return this._renderable;
  }
  public override set renderable(value: RenderableCheckbox) {
    this._renderable = value;
  }

  constructor(private modelPropertyService: ModelPropertyService) {
    super();
  }

  public isChecked() {
    return !!this.form.controls[this.renderable.id].value;
  }

  public valueChanged(event: any) {
    let checked = event.target.checked;
    this.form.controls[this.renderable.id].setValue(checked);
    this.form.controls[this.renderable.id].markAsTouched();
  }

  public isRequired(bindingId: string): boolean {
    return this.modelPropertyService.isRequired(bindingId);
  }
}
