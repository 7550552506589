<!--div class="fw-bold mb-3">
  {{ renderable.help | wsTrans: renderable.id + '_help' : renderable.bind + '_help'|async|wsInterpolate|async}}
</div>

<div class="fw-bold mb-3">
  {{ renderable.hint | wsTrans: renderable.id + '_hint' : renderable.bind + '_hint'|async|wsInterpolate|async}}
</div-->

<app-ws-constraint-alert [renderable]="renderable" [form]="form" *ngIf="form"></app-ws-constraint-alert>
<div [formGroup]="form" [class]="!isValid() ? 'form-group input-group has-error' : 'form-group input-group'">

  <div class="form-check">

    <input [formControlName]="renderable.id" [id]="renderable.id" [type]="renderable.type"
      [readonly]="renderable.readonly" [checked]="isChecked()" (change)="valueChanged($event)"
      class="form-check-input" />
    <label *ngIf="renderable.checkbox_label" [for]="renderable.id">
      {{ renderable.checkbox_label | wsTrans: renderable.id + '_checkbox_label'|async|wsInterpolate|async}}
    </label>
    <label *ngIf="!renderable.checkbox_label" class="form-check-label" [for]="renderable.id">
      {{ renderable.label|wsTrans:(renderable.msgid || renderable.id):renderable.bind|async|wsInterpolate|async }}
    </label>
    <fa-icon [icon]="['fas', 'info-circle']" class="ms-2 text-info"
      *ngIf="(renderable.info | wsTrans: '__if_exists__' + renderable.id + '_info' : '__if_exists__' + renderable.bind + '_info' | async | wsInterpolate | async) as condition"
      ngbTooltip="{{renderable.info | wsTrans: '__if_exists__' + renderable.id + '_info' : '__if_exists__' + renderable.bind + '_info' | async | wsInterpolate | async}}">
    </fa-icon>
    <fa-icon *ngIf="isRequired(renderable.bind)" [icon]="['fas', 'asterisk']" class="ws-required ms-1"></fa-icon>
  </div>
</div>