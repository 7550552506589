import { Injectable } from '@angular/core';
import { Logger } from '../Services/logger.service';
import { BackendService } from '../Services/backend.service';
import { WisApiService } from '../Services/wisapi.service';
import { ViewService } from '../Services/view.service';
import { GlobalService } from '../Services/global.service';
import { DataService } from '../Services/data.service';
import { ModelService } from '../Services/model.service';
import { VocabularyService } from '../Services/vocabulary.service';
import { ViewControlService } from '../Services/view-control.service';
import { Survey } from '../Models/survey';
import { BehaviorSubject } from 'rxjs';
import { RenderableBase } from '../Models/renderable-base';
import { RenderableGroup } from '../Models/renderable-group';
import { RenderableSearchtree } from '../Models/renderable-searchtree';
import { LocalStorageService } from '../Services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  public survey: BehaviorSubject<Survey> = new BehaviorSubject<Survey>(null);
  public renderables: any[];
  private _searchTreesToDo = 0;
  public searchTreesToDo: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public initializing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public surveyInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _flatDataSubscription = null;
  private _externalSurvey = null;

  constructor(
    private logger: Logger,
    private globals: GlobalService,
    private backendService: BackendService,
    private wisApiService: WisApiService,
    private viewService: ViewService,
    private dataService: DataService,
    private modelService: ModelService,
    private vocabularyService: VocabularyService,
    private viewControlService: ViewControlService,
    private settingsStorage: LocalStorageService,
  ) {
    this.settingsStorage = new LocalStorageService();
    this.settingsStorage.setPrefix('settings::')
  }

  public startInitializing() {
    this.initializing.next(true);
  }
  public finishInitializing() {
    this.initializing.next(false);
    this.surveyInitialized.next(true);
  }

  /**
   * Init survey by id locale and params
   *
   * @param id
   * @param locale
   * @param displayLocale
   * @param queryParams
   */
  public async initSurvey(
    id: string,
    locale: string,
    displayLocale: string,
    referrer: string,
    referrer2: string,
    sessionId: string,
    queryParams: {},
    externalSurvey: Survey,
  ) {
    this._externalSurvey = externalSurvey;

    queryParams = queryParams || {};

    this.startInitializing();

    this.globals.surveyId = id;
    this.globals.locale = locale;
    this.globals.setDisplayLocale(displayLocale);
    this.globals.referrer = referrer;
    this.globals.referrer2 = referrer2;

    this._saveSettings(queryParams);

    if (sessionId && !externalSurvey) {
      // a sessionId was requested, so we try to load the data
      this.logger.info(`loading previous session: ${sessionId}`);
      this.backendService.getSurveyData(sessionId).subscribe(
        async (data) => {
          if (data && Object.entries(data).length > 0) {
            // set some globals from the loaded session
            // you can't load a session from another survey e.g.
            // this wouldn't make sense
            const sessionSurveyId = data['meta']['survey_id'];
            const sessionLocale = data['meta']['locale'] || data['data']['locale1'];
            const sessionReferrer = data['data']['referrer'];
            const sessionReferrer2 = data['data']['referrer2'];
            data['data']['session-id'] = sessionId; // just to make sure

            if (sessionSurveyId) {
              this.globals.surveyId = sessionSurveyId;
            }
            if (sessionLocale && !queryParams['locale']) {
              this.globals.locale = sessionLocale;
            }
            if (sessionReferrer && !queryParams['referrer']) {
              this.globals.referrer = sessionReferrer;
            }
            if (sessionReferrer2 && !queryParams['referrer2']) {
              this.globals.referrer2 = sessionReferrer2;
            }

            try {
              const survey = await this.backendService
                .getRemoteSurvey(
                  this.globals.surveyId,
                  this.globals.locale,
                  this.globals.getDisplayLocale(),
                )
                .toPromise();

              this._setSurvey(
                survey,
                this.globals.getDisplayLocale(),
                this.globals.referrer,
                this.globals.referrer2,
                queryParams,
                data['data'],
              );
              this.dataService.refreshData(true, false);
              // store a flag in the meta data that this survey was reloaded
              // with a sessionId and values will be overwritten in DB
              this.dataService.setPageMetaValue('re-edit', true);
              // after the session was reloaded we want to skip to the first
              // page of the survey (I think) or should we just go to the last
              // page that was saved in the session?
              const pageMetaData = this.dataService.getPageMetaData();
              if ('__page_stateId' in pageMetaData) {
                delete pageMetaData['__page_stateId'];
              }
              this.initLocaleChanges();
              //this.finishInitializing();
            } catch (error) {
              this.logger.error(error);
            }
          }
        },
        (error) => {
          this.logger.error(error);
        },
      );
    } else {
      try {
        let survey = externalSurvey;
        if (!survey) {
          survey = await this.backendService
            .getRemoteSurvey(id, this.globals.locale, this.globals.getDisplayLocale())
            .toPromise();
        }

        // Vieze hack.. als referrer in calculated (of default) survey value is, dan deze gebruiken
        const dataReferrer = survey.data['referrer'];
        if (dataReferrer && !queryParams['referrer'] && !this.globals.referrer) {
          this.globals.referrer = dataReferrer;
        }
        const dataReferrer2 = survey.data['referrer2'];
        if (dataReferrer2 && !queryParams['referrer2'] && !this.globals.referrer) {
          this.globals.referrer2 = dataReferrer2;
        }

        this._setSurvey(survey, displayLocale, referrer, referrer2, queryParams, null);
        if (sessionId) {
          this.dataService.setValue('session-id', sessionId);
        }
        this.dataService.setValue('locale', displayLocale || locale);
        this.dataService.refreshData(true, false);
        this.initLocaleChanges();
        //this.finishInitializing();
      } catch (error) {
        this.logger.error(error);
      }
    }
  }

  public initLocaleChanges() {
    // subscribe to locale changes. If changed, reload survey and replace translations
    // TODO: this code should be moved to some other place probably..
    //
    if (this._flatDataSubscription) {
      this._flatDataSubscription.unsubscribe();
    }

    this._flatDataSubscription = this.dataService.flatData$.subscribe(async (flatdata) => {
      // store the translation_suffix in the globals so trans pipe can use it
      this.globals.translationSuffix = this.dataService.getItem('translation_suffix');

      if ('translation_suffix' in flatdata) {
        const ctrl = flatdata['translation_suffix'][0];
        (ctrl as any).valueChanges.subscribe((value) => {
          this.globals.translationSuffix = value;
        });
      }

      if ('locale' in flatdata) {
        // locale might have been loaded from session data
        const restoredLocale = this.dataService.getItem('locale');

        const localeControl = flatdata['locale'][0]; // take first bind with name 'locale'

        (localeControl as any).valueChanges.subscribe((value) => {
          if (value && value !== this.globals.getDisplayLocale()) {

            this.globals.setDisplayLocale(value);
            if (this._externalSurvey) {
              this._loadExternalSurvey();
            } else {
              this._loadRemoteSurvey();
            }
          }
        });

        if (restoredLocale && restoredLocale !== this.globals.getDisplayLocale()) {
          this.globals.setDisplayLocale(restoredLocale);
          if (this._externalSurvey) {
            this._loadExternalSurvey();
          } else {
            this._loadRemoteSurvey();
          }
        }

        // check if locale exists in the wisapi languages. e.g. nl_BE doesn't exist
        if (restoredLocale) {
          const renderablesByBind = this.viewControlService.getRenderablesByBind(this.renderables);
          if ('locale' in renderablesByBind) {
            const renderable = renderablesByBind['locale'];
            const searchtreeId = (renderable as any).searchtree_id;
            // assume no optionref for locale..
            if ((renderable as any).optionref) {
              this.logger.error("wasn't expecting an optionref for locale searchtree");
            }
            // fetch wisapi
            let matchedLocale = restoredLocale;
            if (searchtreeId) {
              true;
              const result = await this.wisApiService
                .getRemoteSearchtree(searchtreeId, restoredLocale, restoredLocale)
                .toPromise();
              let matched = false;
              for (const child of result.children) {
                if (restoredLocale === child.id) {
                  matched = true;
                  matchedLocale = child.id;
                  break;
                }
              }
              if (!matched) {
                for (const child of result.children) {
                  if (restoredLocale.split('_')[0] === child.id.split('_')[0]) {
                    matchedLocale = child.id;
                  }
                }
              }
              this.dataService.setValue('locale', matchedLocale);
            }
          }
        }
      }
    });
  }

  private _saveSettings(queryParams: {}) {
    // HUUB: I have no idea why this method lives here
    // I think it would make more sense in the loading
    // component but it works so it's ok for now
    if (this.settingsStorage) {
      // clear all local storage items that don't start with 'persist::'
      this.settingsStorage.clear(/.*/);

      this.settingsStorage.set('locale', this.globals.locale);
      this.settingsStorage.set('displayLocale', this.globals.getDisplayLocale());
      this.settingsStorage.set('surveyId', this.globals.surveyId);
      this.settingsStorage.set('referrer', this.globals.referrer);
      this.settingsStorage.set('referrer2', this.globals.referrer2);

      for (const key of Object.keys(queryParams)) {
        if (!(key in this.globals)) {
          this.settingsStorage.set(key, queryParams[key]);
        }
      }
    }
  }

  private _setSurvey(
    survey: {},
    locale: string,
    referrer: string,
    referrer2: string,
    queryParams: {},
    data: {},
  ) {
    const newSurvey = new Survey(survey);

    // TODO, we might consider creating the vocabs in the 'view service'
    this.vocabularyService.setRawVocabularies(newSurvey['view']['components']);

    const rawData = { ...newSurvey['data'], ...data }; // merge the two dicts
    this.dataService.setRawData(rawData, queryParams);

    this.viewService.setRawView(newSurvey['view']);
    this.modelService.setRawModel(newSurvey['model']);

    if (locale) {
      this.dataService.setValue('locale', locale);
    }
    if (referrer) {
      this.dataService.setValue('referrer', referrer);
    }
    if (referrer2) {
      this.dataService.setValue('referrer2', referrer2);
    }

    this.dataService.refreshData(true, false);

    this.renderables = this.viewService.getRenderables();
    this.viewControlService.setRenderables(this.renderables);

    this.survey.next(newSurvey);

    // we evaluate the calculates, then refresh the cache then do another round
    // of calculates. This is necessary for dificult bootstrapped fields which
    // depend on other calculated values like we have in the decent work check
    this.viewControlService.evaluateCalculates();
    this.dataService.refreshData(true, false);
    this.viewControlService.evaluateCalculates();

    this.dataService.dataChanged.subscribe(() => {
      this.viewControlService.evaluateCalculates(false);
    });

    // this.pageGroupService.setupPageRegistry(this.renderables);
    // this.pageGroupService.activateInitialPage();

    if (this.globals.OFFLINE) {
      this._initializeOfflineResources();
    }

    this.finishInitializing();
  }

  private _initializeOfflineResources() {
    const renderablesByBind = this.viewControlService.getRenderablesByBind(this.renderables);

    /* Special business logic for offline surveys:
     * - 'f2f' field is set to 1 ("face 2 face" as it has been called in the old survey)
     * - 'locale' selector is turned 'off' (readonly)
     * - all renderables that influence other searchtree are disabled
     */

    this.dataService.setRawValue('f2f', 1);

    if ('locale' in renderablesByBind) {
      // business logic: not allowed to change the locale field in the offline survey
      (renderablesByBind['locale'] as any).readonly = true;
    }

    const searchtreeRenderables = [];

    this._findSearchTreeRenderables(this.renderables, searchtreeRenderables);
    this._searchTreesToDo = searchtreeRenderables.length;
    this.searchTreesToDo.next(this._searchTreesToDo);
    for (const renderable of searchtreeRenderables) {
      let discriminator = null;
      if (renderable.optionref) {
        discriminator = this.dataService.getItem(renderable.optionref);
        if (renderable.optionref in renderablesByBind) {
          const optionrefRenderable = renderablesByBind[renderable.optionref];
          // not sure if the readonly attribute is the best option here
          optionrefRenderable.readonly = true;
        }
      }
      if (discriminator == null) {
        discriminator = renderable.initial;
      }

      this.wisApiService
        .getRemoteSearchtree(
          renderable.searchtree_id,
          this.globals.locale,
          this.globals.getDisplayLocale(),
          discriminator,
        )
        .subscribe((result) => {
          this._searchTreesToDo -= 1;
          this.searchTreesToDo.next(this._searchTreesToDo);
        });
    }
  }

  protected _findSearchTreeRenderables(renderables: Array<RenderableBase<any>>, result: any[]) {
    for (const renderable of renderables) {
      if (renderable instanceof RenderableSearchtree) {
        result.push(renderable);
      }
      if (renderable instanceof RenderableGroup) {
        this._findSearchTreeRenderables(renderable.subrenderables, result);
      }
    }
  }

  protected _loadExternalSurvey() {
    const survey = this._externalSurvey;
    // survey['css'] = [];
    this.survey.next(survey);
  }

  protected _loadRemoteSurvey() {
    this.backendService
      .getRemoteSurvey(this.globals.surveyId, this.globals.locale, this.globals.getDisplayLocale())
      .subscribe(
        (remoteSurvey) => {
          const survey = this.survey.getValue();
          survey['translations'] = remoteSurvey['translations'];
          this.survey.next(survey);
        },
        (error) => {
          this.logger.error(error);
        },
      );
  }
}
