import {
  Component,
  Input,
  ViewChild,
  ComponentRef,
  ViewContainerRef,
  AfterViewInit,
} from '@angular/core';
import { RenderableBase } from '../Models/renderable-base';
import { GlobalService } from '../Services/global.service';
import { BaseFormControlComponent } from '../Components/base-form-control.component';
import { ModelPropertyService } from '../Services/modelproperty.service';
import { AbstractControl } from '@angular/forms';
import { LinkComponent } from './link.component';
import { HrComponent } from './hr.component';
import { ExternalCommunicationService } from '../Services/external.service';
import { Subscription } from 'rxjs';
import { CardGroupComponent } from './cardgroup.component';

@Component({
  selector: 'app-df-renderable',
  templateUrl: '../Templates/dynamic-form-question.component.html',
  styleUrls: ['../Styles/dynamic-form.question.component.scss'],
})
export class DynamicFormQuestionComponent extends BaseFormControlComponent
  implements AfterViewInit {

  protected override _renderable: RenderableBase<any>;
  @Input()
  public override set renderable(value: RenderableBase<any>) {
    this._renderable = value;
  }
  public override get renderable(): RenderableBase<any> {
    return this._renderable;
  }

  @Input() public parentCardGroup: CardGroupComponent;
  @ViewChild('questionHeadersContainer', { read: ViewContainerRef }) container;
  private componentRefs: Array<ComponentRef<any>>;
  // private extContextSubscriber = null;
  private extQuestionHeadersSubscriber: Subscription;

  constructor(
    public globals: GlobalService,
    private modelpropertyService: ModelPropertyService,
    private extCommService: ExternalCommunicationService,
  ) {
    super();
  }

  public ngAfterViewInit() {
    this.extQuestionHeadersSubscriber = this.extCommService
      .questionHeadersListner()
      .subscribe((data) => {
        this.setExtraQuestionHeaders(data);
      });
  }

  public setExtraQuestionHeaders(data) {
    if (this.container) {
      this.container.clear();

      if (this.componentRefs) {
        for (const ref of this.componentRefs) {
          ref.instance.output.unsubscribe();
          ref.hostView.detach();
          ref.destroy();
        }
      }

      this.componentRefs = [];

      if (data) {
        data.forEach((question) => {
          if (question.el == 'hr') {

            const componentRef = this.container.createComponent(HrComponent);
            this.componentRefs.push(componentRef);
            componentRef.instance.bind = this.renderable.bind;
            componentRef.instance.type = this.renderable.type;
            componentRef.instance.class = question.class;
            componentRef.instance.hostClass = question.hostClass;
            componentRef.instance.condition = question.condition;

            // we need to force the new component to redraw before they update
            // if e.g. they have been hidden in a cardgroup
            componentRef.hostView.detectChanges();
          }
          else if (question.el == 'a') {
            const componentRef = this.container.createComponent(LinkComponent);
            this.componentRefs.push(componentRef);
            componentRef.instance.bind = this.renderable.bind;
            componentRef.instance.type = this.renderable.type;
            componentRef.instance.specifier = question.specifier;
            componentRef.instance.title = question.title;
            componentRef.instance.class = question.class;
            componentRef.instance.hostClass = question.hostClass;
            componentRef.instance.label = question.label;
            componentRef.instance.faIcon = question.faIcon;
            componentRef.instance.condition = question.condition;

            // we need to force the new component to redraw before they update
            // if e.g. they have been hidden in a cardgroup
            componentRef.hostView.detectChanges();

            componentRef.instance.output.subscribe((event) => {
              let payload = [
                event,
                {
                  bind: this.renderable.bind,
                  specifier: question.specifier,
                  value: this.renderable.value,
                  lexicalValue: this.renderable.lexicalValue(),
                },
              ];
              this.extCommService.emitGenericEvent(JSON.stringify(payload));
            });
          }
        });
      }
    }
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.componentRefs) {
      for (const ref of this.componentRefs) {
        if (ref.instance.output) {
          ref.instance.output.unsubscribe();
        }
        ref.destroy();
      }
    }
    if (this.extQuestionHeadersSubscriber) {
      this.extQuestionHeadersSubscriber.unsubscribe();
    }
  }

  public debugRepresentation(renderable: RenderableBase<any>): string {
    if (!renderable.bind) {
      return '';
    }
    let result = this.form.controls[renderable.id].value;
    if (result && Array.isArray(result)) {
      result = result.map((item) => {
        if (item.hasOwnProperty('label') && item.label) {
          return `${item.id} (${item.label})`;
        }
        if (item.hasOwnProperty('id') && item.id) {
          return item['id'];
        }
        return item;
      });
      result = result.toString();
    }
    return result;
  }

  /**
   * Add some conditional extra class attributes.
   * @returns {string}
   */
  public additionalClasses(): string {
    let result: string[] = [];

    if (!this.isValid()) {
      result.push('ws-warning');
    }

    if (this.renderable.type !== 'hidden') {
      result.push('card-block question');
    }

    if (this.renderable.extra_classes !== '') {
      result.push(this.renderable.extra_classes);
    }

    return result.join(' ')
  }

  public isRequired(bindingId: string): boolean {
    return this.modelpropertyService.isRequired(bindingId);
  }

  public wrapBaseFormControlComponent(control: AbstractControl): BaseFormControlComponent {
    const casted = control as unknown;
    return casted as BaseFormControlComponent;
  }
}
