import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { RenderableSubmit } from '../Models/renderable-submit';
import { BaseFormControlComponent } from './base-form-control.component';
import { BackendService } from '../Services/backend.service';
import { DataService } from '../Services/data.service';
import { GlobalService } from '../Services/global.service';
import { Logger } from '../Services/logger.service';
import { Utils } from '../Utils/utils';
import { RenderableBase } from '../Models/renderable-base';

@Component({
  selector: 'app-ws-submit',
  templateUrl: '../Templates/submit.component.html',
})
export class SubmitComponent extends BaseFormControlComponent {
  protected override _renderable: RenderableSubmit;
  @Input()
  public override get renderable(): RenderableSubmit {
    return this._renderable;
  }
  public override set renderable(value: RenderableBase<any>) {
    this._renderable = value as RenderableSubmit;
  }

  protected override _form: FormGroup<any>;
  @Input()
  override set form(control: AbstractControl) {
    this._form = control as FormGroup<any>;
  }
  override get form(): FormGroup<any> {
    return this._form;
  }

  constructor(
    private logger: Logger,
    public globals: GlobalService,
    private dataService: DataService,
    private backendService: BackendService,
  ) {
    super();
  }

  public getStatus() {
    return `state-${this.form.root.valid ? 'valid' : 'invalid'}`;
  }

  public isActive() {
    // the button is active if the complete form is valid
    return this.form.root.valid;
  }

  protected _recursiveMarkAsTouched(control) {
    control.markAsTouched();
    if (control instanceof UntypedFormGroup) {
      for (const ctrl in control.controls) {
        if (control.controls.hasOwnProperty(ctrl)) {
          // eigenlijk zouden we nu alleen de controls die relevant (zichtbaar) zijn
          // moeten touchen, maar dit werkt ook wel gied genoeg voor nu..
          this._recursiveMarkAsTouched(control.controls[ctrl]);
        }
      }
    }
  }

  /* This posts the survey to the rest backend. Note that no page
   * timing metadata is stored. This is a basic submission component
   * probably only useful in basic surveys without pagegroups
   */
  public submitSurvey(event: any) {
    if (this.isActive()) {
      // a button cannot have a formControlName (I think)
      // so this workaround is used to store the value
      // just save the current timestamp as value
      const now = Math.floor(Date.now() / 1000);
      this.dataService.setValue(this.renderable.bind, now);

      const data = this.dataService.getDataForSubmission();
      const pageMetaData = this.dataService.getPageMetaData();
      const finalized = true; // will post the survey

      this.backendService.submitSurvey(data, pageMetaData, finalized).subscribe(
        (resp) => {
          this.logger.debug(resp);
        },
        (error) => {
          // what should we do with this error?
          this.logger.error(error);
          this.dataService.setValue(this.renderable.bind, null); // reset value?
        },
      );

      // note: don't store the data in the session. this is only for paginated surveys (for now?)
      // this.dataService.saveLocalStorage();
    } else {
      // submit button was clicked but form is in invalid. So show the errors
      this.form.root.markAsTouched();
      const controls = this.form.root['controls'];
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this._recursiveMarkAsTouched(controls[control]);
        }
      }

      let surveyComponent = Utils.querySelectorInShadowRoots(
        document,
        'app-ws-survey, survey-component',
      );
      let firstInvalid = null;
      if (surveyComponent && surveyComponent.shadowRoot) {
        surveyComponent = surveyComponent.shadowRoot;
      }
      if (surveyComponent) {
        firstInvalid = surveyComponent.querySelector('div[class~="ws-warning"]');
      }

      if (firstInvalid) {
        firstInvalid.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }
}
