import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { RenderableSelect } from '../Models/renderable-select';
import { BaseFormControlComponent } from '../Components/base-form-control.component';
import { RenderableBase } from '../Models/renderable-base';
import { DataService, Logger } from '../Services';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ws-select',
  templateUrl: '../Templates/select.component.html',
  styleUrls: ['../Styles/select.component.scss'],
})
export class SelectComponent extends BaseFormControlComponent {
  protected override _renderable: RenderableSelect;
  @Input()
  public override get renderable(): RenderableSelect {
    return this._renderable;
  }
  public override set renderable(value: RenderableBase<any>) {
    this._renderable = value as RenderableSelect;
  }

  @ViewChild('multiSelectDropdown') multiSelectDropdown: NgbDropdown;

  constructor(
    private dataService: DataService,
    private logger: Logger,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public override ngOnInit() {
    super.ngOnInit();
    if (this.renderable.multiple) {
      // fix labels because widget uses labels from values instead reading them
      // form the vocabs. Sometime the labels are unset (old values from cobra?)
      // or translations might differ for current locale
      const options = this.multiSelectOptions();
      const value = this.form.controls[this.renderable.id].value;
      for (let record of value) {
        for (const opt of options) {
          if (record['id'] == opt['id']) {
            record['label'] = opt['label'];
          }
        }
      }

      this.form.controls[this.renderable.id].setValue(value, { emitEvent: false });
    }
  }

  public onOutsideClick(event: any): void {
    this.multiSelectDropdown.close();
  }

  public isSelected(item: any): boolean {
    const value = this.form.controls[this.renderable.id].value || [];
    if (!Array.isArray(value)) {
      this.logger.error('multiselect value is not an array');
      return false;
    }

    return value.some((v: any) => v.id === item.id);
  }

  public toggleItem(item: any): void {
    console.log('toggleItem');
    let value = this.form.controls[this.renderable.id].value || [];

    if (this.renderable.multiple) {
      if (!Array.isArray(value)) {
        this.logger.error('multiselect value is not an array');
        return; // Exit if the value is not an array as expected
      }
      const index = value.findIndex((v: any) => v.id === item.id);

      if (index > -1) {
        // Item found, remove it
        value.splice(index, 1);
      } else {
        // Item not found, add it
        value.push(item);
      }

      // Update the form control
      this.form.controls[this.renderable.id].setValue(value);
      this.form.controls[this.renderable.id].markAsTouched();
    }
  }

  public multiSelectOptions() {
    let options = [];
    for (const opt of this.renderable.options) {
      options.push({ id: opt.value, label: opt.label });
    }
    for (const opt of this.renderable.vocabRefOptions()) {
      options.push({ id: opt.value, label: opt.label });
    }
    for (const opt of this.renderable.vocabFromBindOptions()) {
      options.push({ id: opt.value, label: opt.label });
    }

    return options;
  }

  /**
   * Hack to get ngbRadioGroup working for dynamic forms
   * ALSO: before getting rid of this, check if toggle off still works
   * without this hack
   * @param event The click event
   * @param value the option value
   */
  public onRadioClicked(event: any, value: any = null) {

    let clickedValue = value;
    if (value === null) {
      clickedValue = event.target.children[0].value;
    }

    // uncheck value if the current value was re-selected (like a toggle)
    if (String(clickedValue) === String(this.form.controls[this.renderable.id].value)) {
      clickedValue = null;
    }

    this.form.controls[this.renderable.id].setValue(clickedValue);
    this.form.controls[this.renderable.id].markAsTouched();

    return false;
  }

  public inactive(value: number) {
    /* a bit of a hack: we can't change the 'active' class on the radiogroup
     * this seems to be impossible. So we just add an inactive class and add
     * some special inactive styling to the scss
     */
    return String(this.form.controls[this.renderable.id].value) !== String(value);
  }

  public onKeyPressed(event: any) {
    // if (event.keyCode === 13) {
    //   this.onRadioClicked(event);
    // }
  }

  public needsFill(value: number): boolean {
    let result = false;

    if (this.renderable['show_fill']) {
      if (this.form.controls[this.renderable.id].value) {
        result = +value <= +this.form.controls[this.renderable.id].value;
      }
    }

    return result;
  }

  trackByOptions(index: number, option): string {
    return option.value;
  }

}
